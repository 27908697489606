<template>
  <dlg-confirm
    v-if="!dataConfirm"
    @response="onConfirm"
    :show.sync="showFlag"
    title="데이터 사용 알림"
    message="블랙박스 라우터의 데이터를 사용합니다.<br>계속하시겠습니까?">
    <template v-slot:body>
      <div class="dlg-confirm-text">
        <div v-html="'블랙박스 라우터의 데이터를 사용합니다.<br>계속하시겠습니까?'"></div>
        <v-checkbox
          v-model="flag"
          hide-details
          dense
          label="오늘 하루 보지 않기">
        </v-checkbox>
      </div>
    </template>
  </dlg-confirm>
</template>

<script>
import DlgConfirm from '@/components/dialog/DlgConfirm.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'DlgDataConfirm',
  components: {
    DlgConfirm,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    flag: false,
  }),
  computed: {
    ...mapGetters({
      dataConfirm: 'config/dataConfirm',
    }),
    showFlag: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      },
    },
  },
  watch: {
    dataConfirm: {
      immediate: true,
      handler(value) {
        if (value) {
          this.$emit('response', true);
          this.$emit('update:show', false);
        }
      },
    },
  },
  methods: {
    ...mapMutations({
      setDataConfirm: 'config/dataConfirm',
    }),
    onConfirm(result) {
      if (result && this.flag) {
        this.setDataConfirm({ config: { flag: true }, commit: true });
      }
      this.$emit('response', result);
    },
  },
};
</script>

<style lang="scss">
.dlg-confirm-text {
  font-size: 1rem;

  .v-label {
    font-size: .9rem;
  }
}
</style>
