<template>
  <layout-card
    :width="300"
    :height="200"
    title="현재 상태">
    <template v-slot:body>
      <div
        style="height: 100%;"
        class="d-flex">
        <div class="ma-auto">
          <v-avatar
            class="mb-3"
            color="#e4eff1"
            size="88px">
            <template v-if="drivingStatus">
              <img
                class="pa-1"
                alt="icon"
                :src="icon"/>
            </template>
            <template v-else>
              <v-icon
                color="#9CBED1"
                size="54">
                {{ icon }}
              </v-icon>
            </template>
          </v-avatar>
          <div class="card-run-state-value">
            <span>{{ message }}</span>
          </div>
        </div>
      </div>
    </template>
  </layout-card>
</template>

<script>
import LayoutCard from '@/components/card/LayoutCard.vue';
import IconDriving from '@/assets/icon_02.svg';

export default {
  name: 'CardRunStatus',
  components: {
    LayoutCard,
  },
  props: {
    park: {
      type: Number,
      default: 1,
    },
    stat: {
      type: String,
      default: 'disconnected',
    },
  },
  data: () => ({
    iconDriving: IconDriving,
  }),
  computed: {
    drivingStatus() {
      return this.deviceStatus && (this.park === 0);
    },
    deviceStatus() {
      return this.stat === 'connected';
    },
    icon() {
      let icon = '';

      if (this.drivingStatus) {
        icon = this.iconDriving;
      } else if (this.deviceStatus) {
        icon = 'mdi-parking';
      } else {
        icon = 'mdi-lan-disconnect';
      }

      return icon;
    },
    message() {
      let msg = '';

      if (this.drivingStatus) {
        msg = '운행중';
      } else if (this.deviceStatus) {
        msg = '주차중';
      } else {
        msg = '연결끊김';
      }

      return msg;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-run-state-value {
  line-height: normal;
  font-size: 1.6rem;
  font-weight: bold;
  color: #31b9ef;
}
</style>
