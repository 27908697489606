<template>
  <layout-card
    :width="300"
    :height="200"
    title="실시간 영상">
    <template v-slot:title>
      실시간 영상
      <v-spacer>
      </v-spacer>
      <div class="time-string">
        {{ Math.ceil(currentTime) }} / {{ Math.ceil(totalDuration) }}s
      </div>
    </template>
    <template v-slot:body>
      <v-card
        flat
        rounded>
        <live-player
          v-if="!hidePlayer"
          class="live-player"
          @play="checkOnline"
          @stop="stopLive(true)"
          @timeupdate="onTimeupdate"
          :width="252"
          :height="142"
          :loading.sync="loading"
          :src.sync="src">
        </live-player>
      </v-card>
      <dlg-data-confirm
        @response="onConfirm"
        v-if="confirmShow"
        :show.sync="confirmShow">
      </dlg-data-confirm>
    </template>
  </layout-card>
</template>

<script>
import MixinCheckOnline from '@/components/card/MixinCheckOnline';
import LayoutCard from '@/components/card/LayoutCard.vue';
import LivePlayer from '@/components/output/LivePlayer.vue';
import DlgDataConfirm from '@/components/dialog/DlgDataConfirm.vue';

export default {
  name: 'CardLive',
  mixins: [MixinCheckOnline],
  components: {
    LayoutCard,
    LivePlayer,
    DlgDataConfirm,
  },
  props: {
    name: {
      type: String,
      default: '-',
    },
    serial: {
      type: String,
      default: '-',
    },
  },
  data: () => ({
    type: 3,
    src: '',
    loading: false,
    stopFlag: false,
    currentTime: 0,
    totalDuration: 0,
    hidePlayer: false,
  }),
  watch: {
    serial: {
      handler() {
        if (this.loading || this.src !== '') {
          this.stopLive();
        }
        this.reset();
      },
    },
  },
  sockets: {
    'live.play': function _(data) {
      console.log('live.play:', data);

      const serial = (data.result === 'success') ? (data.serial) : (data.target.slice(3));

      if (serial === this.serial) {
        if (data.result === 'success') {
          console.log('live.play:', data);

          let url = `https://bbnms.cns-link.net:8080/hls/${data.url}.m3u8`;

          if (data.uri != null) {
            url = data.uri.replace('rtmp', 'https')
              .replace('49.247.0.154', 'bbnms2.cns-link.net')
              .replace('live', 'hls')
              .replace(':1935', ':8080')
              .concat('.m3u8');
          }
          this.src = url;
        } else {
          let { message } = data;

          if (data.name === 'ETIMEOUT') {
            message = '블랙박스 응답이 없습니다';
          }

          this.$store.dispatch('alert/setAlert', {
            message: `[${this.serial}] ${message}`,
            color: 'error',
          });
        }

        this.loading = false;
      }
    },
    'live.stop': function _(data) {
      console.log('live.stop:', data);

      const serial = (data.result === 'success') ? (data.serial) : (data.target.slice(3));

      if (serial === this.serial) {
        if (data.result === 'success') {
          if (!this.stopFlag) {
            this.playLive();
          }
        } else {
          let { message } = data;

          if (message === 'Does not respond') {
            message = '블랙박스로부터 응답이 없습니다.';
          }

          this.$store.dispatch('alert', {
            color: 'error',
            message: `[${this.serial}] ${message}`,
          });
        }
      }
    },
  },
  methods: {
    reset() {
      this.loading = false;
      this.currentTime = 0;
      this.totalDuration = 0;

      this.hidePlayer = true;
      this.$nextTick(() => {
        this.hidePlayer = false;
      });
    },
    startLive() {
      this.loading = true;
      this.playLive();
    },
    playLive() {
      this.$socket.emit('live.play', {
        serial: this.serial,
        type: this.type,
        protocol: 2,
      });
    },
    stopLive(flag) {
      this.stopFlag = flag;
      this.$socket.emit('live.stop', {
        serial: this.serial,
        protocol: 2,
      });

      this.src = '';
    },
    onTimeupdate({ currentTime, totalDuration }) {
      this.currentTime = currentTime;
      this.totalDuration = totalDuration;
    },
    onConfirm(result) {
      if (result) {
        this.startLive();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.time-string {
  font-size: 1rem;
  color: #7E7E7E;
}

.live-player {
  padding-right: 10px;
  padding-left: 10px;
}
</style>
