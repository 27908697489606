import { mapGetters } from 'vuex';

export default {
  props: {
    height: {
      type: Number,
      default: 240,
    },
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      devices: 'devices/devices',
    }),
  },
  methods: {
    drivingStatus(item) {
      return this.deviceStatus(item) && (item.park === 0);
    },
    deviceStatus(item) {
      return item.stat === 'connected';
    },
    gpsStatus(item) {
      return this.deviceStatus(item)
        && item.gpsStatus
        && item.gpsStatus === 'valid';
    },
    getDeviceAllowState(item) {
      let msg = '';
      switch (item.temp_flag) {
        case 1: msg = '승인 대기'; break;
        case 2: msg = '승인 거부'; break;
        default: break;
      }
      return msg;
    },
  },
};
