<template>
  <v-card
    tile
    flat
    min-width="480">
    <v-card-title>
      <span>
        실시간 영상 ({{ devices.length }} 대)
      </span>
      <v-spacer>
      </v-spacer>
      <span
        class="title-guide">
        최대 9개까지 선택 가능합니다.
      </span>
    </v-card-title>
    <v-divider
      class="title-divider">
    </v-divider>
    <v-card-text>
      <div
        class="list-container">
        <v-list-item
          class="list-header">
          <v-list-item-avatar>
            <span>상태</span>
          </v-list-item-avatar>
          <v-list-item-content
            class="flex-grow-1">
            <span>이름</span>
          </v-list-item-content>
          <v-list-item-action
            class="d-flex flex-row align-center">
            <div class="ml-5">
              BB
            </div>
            <div class="ml-5 mr-5">
              GPS
            </div>
          </v-list-item-action>
        </v-list-item>
        <v-virtual-scroll
          style="overflow-y: scroll;"
          :bench="5"
          :items="devices"
          :height="height"
          item-height="64">
          <template v-slot:default="{ index, item }">
            <v-overlay
              v-if="item.temp_flag > 0"
              absolute
              opacity="0.9">
              {{ getDeviceAllowState(item) }}
            </v-overlay>
            <v-list-item
              :key="`item_${index}`">
              <v-list-item-action>
                <v-checkbox
                  v-model="liveSelected"
                  color="#1D7CC1"
                  :disabled="(!isSelected(item) && selectCnt >= liveLimit)"
                  :value="item.serial">
                </v-checkbox>
              </v-list-item-action>
              <v-list-item-content
                class="flex-grow-1">
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.serial }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action
                class="d-flex flex-row align-center">
                <div class="ml-2 mr-4">
                  <v-icon
                    :color="deviceStatus(item) ? '#7AFE00' : '#7F7F7F'"
                    small>
                    mdi-square
                  </v-icon>
                </div>
                <div class="ml-2 mr-2">
                  <v-icon
                    :color="gpsStatus(item) ? '#7AFE00' : '#7F7F7F'"
                    small>
                    mdi-square
                  </v-icon>
                </div>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-virtual-scroll>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import devicelist from './devicelist';

export default {
  name: 'LiveDeviceTable',
  mixins: [
    devicelist,
  ],
  data: () => ({
    headers: [
      { text: '선택' },
      { text: '시리얼', value: 'serial' },
      { text: '차량번호', value: 'name' },
      { text: 'GPS', value: 'gps' },
      { text: 'BB', value: 'status' },
    ],
    selectLimit: 4,
    selected: [],
  }),
  computed: {
    ...mapGetters({
      liveLimit: 'devices/liveLimit',
    }),
    selectCnt() {
      return this.liveSelected.length;
    },
    liveSelected: {
      get() {
        return this.$store.getters['devices/liveSelected'];
      },
      set(selected) {
        this.updateLiveSelected(selected);
      },
    },
  },
  methods: {
    ...mapActions({
      updateLiveSelected: 'devices/updateLiveSelected',
    }),
    isSelected(item) {
      return (this.liveSelected.findIndex((serial) => serial === item.serial)) >= 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card__title {
  color: #1D7CC1;
  font-weight: bold;
  padding: .5rem 1rem;

  .title-guide {
    font-size: 1rem;
    color: #585858;
  }
}

.title-divider {
  border-color: #B1B1B1;
  border-width: 1px;
}

.list-container {
  border: #E0E0E0 1px solid;
  padding: .14rem;
}

.list-header {
  min-height: 36px;
  font-weight: 800;
  background-color: #E0E0E0;
  font-size: 1rem;
  margin-bottom: .25rem;
  padding-left: .5rem;

  .v-avatar {
    color: #585858;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 2rem;
    height: 32px !important;
  }
  .v-list-item__content {
    color: #585858;
    padding-top: 0;
    padding-bottom: 0;
  }
  .v-list-item__action {
    color: #585858;
    margin-top: 0;
    margin-bottom: 0;
  }
}
</style>
