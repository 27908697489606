<template>
  <layout-card
    :width="300"
    :height="200"
    title="데이터 사용량">
    <template v-slot:body>
      <div
        style="height: 100%;"
        class="d-flex flex-row justify-center align-end pb-3">
        <div class="justify-center pb-2">
          <div class="text-label mb-2">사용량</div>
          <div class="text-value">{{ toValue(usage) }}</div>
          <div class="text-label mt-2">{{ toUnit(usage) }}</div>
        </div>
        <div>
          <div
            class="pl-4 pr-4 usage-chart-container"
            ref="chart">
          </div>
        </div>
        <div class="justify-center pb-2">
          <div class="text-label mb-2">전체</div>
          <div class="text-value">{{ toValue(kbLimit) }}</div>
          <div class="text-label mt-2">{{ toUnit(kbLimit) }}</div>
        </div>
      </div>
    </template>
  </layout-card>
</template>

<script>
import { GoogleCharts } from 'google-charts';

import LayoutCard from '@/components/card/LayoutCard.vue';

export default {
  name: 'CardDataUsage',
  components: {
    LayoutCard,
  },
  props: {
    limit: {
      type: Number,
      default: -1,
    },
    usage: {
      type: Number,
      default: -1,
    },
  },
  computed: {
    kbLimit() {
      return (this.limit > 0) ? (this.limit * 1024) : (-1);
    },
    usageRatio() {
      return (this.limit > 0) ? ((this.usage * 100) / this.kbLimit) : 100;
    },
    remainRatio() {
      const remain = (this.limit > 0) ? (((this.kbLimit - this.usage) * 100) / this.kbLimit) : 0;
      return (remain < 0) ? 0 : remain;
    },
  },
  watch: {
    remainRatio: {
      immediate: true,
      handler() {
        GoogleCharts.load(() => {
          console.log('api:', GoogleCharts);

          // Standard google charts functionality is available as GoogleCharts.api after load
          const data = GoogleCharts.api.visualization.arrayToDataTable([
            ['Chart thing', 'Chart amount'],
            ['남은량', this.remainRatio],
            ['사용량', this.usageRatio],
          ]);

          const pieChart = new GoogleCharts.api.visualization.PieChart(this.$refs.chart);
          pieChart.draw(data, {
            legend: 'none',
            pieSliceText: 'none',
            tooltip: { trigger: 'none' },
            slices: {
              0: { color: '#D1D1D1' },
              1: { color: '#4E6DC7' },
            },
            width: 120,
            height: 120,
            chartArea: {
              left: 8,
              top: 8,
              width: '87%',
              height: '87%',
            },
          });
        });
      },
    },
  },
  methods: {
    toValue(usage) {
      let ret = usage / 1024;

      if (ret > 1024) {
        ret /= 1024;
      }

      ret = Math.round(ret * 10) / 10;

      return (usage > 0) ? ret : '-';
    },
    toUnit(usage) {
      const ret = usage / 1024;
      let unit = 'MB';

      if (ret > 1024) {
        unit = 'GB';
      }

      return unit;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-label {
  color: #9D9D9D;
  font-weight: bold;
  font-size: 1rem;
}

.text-value {
  color: #489AC8;
  font-weight: bold;
  font-size: 1.8rem;
}
</style>
