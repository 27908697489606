<template>
  <layout-dialog
    title="블랙박스 개별 등록"
    :show.sync="showFlag"
    :max-width="600">
    <template v-slot:body>
      <v-card
        flat>
        <v-card-text>
          <v-text-field
            v-model="serial"
            label="블랙박스 시리얼">
          </v-text-field>
          <v-text-field
            v-model="nickname"
            label="등록 이름">
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer>
          </v-spacer>
          <v-btn
            @click="$emit('update:show', false);"
            :disabled="loading"
            color="secondary"
            depressed>
            취소
          </v-btn>
          <v-btn
            @click="onAddDevice"
            :loading="loading"
            color="primary"
            depressed>
            등록
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </layout-dialog>
</template>

<script>
import LayoutDialog from '@/components/dialog/LayoutDialog.vue';
import { mapActions } from 'vuex';

export default {
  name: 'DlgAddDevice',
  components: {
    LayoutDialog,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    serial: '',
    nickname: '',
    loading: false,
  }),
  computed: {
    showFlag: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      },
    },
  },
  sockets: {
    'term.device.add.batch': function _(data) {
      if (data.result === 'success') {
        this.getDevices();

        this.$store.dispatch('alert/setAlert', {
          message: '등록 완료',
          color: 'info',
        });

        this.showFlag = false;
      } else {
        this.$store.dispatch('alert/setAlert', {
          message: data.message,
          color: 'error',
        });
      }

      this.loading = false;
    },
  },
  methods: {
    ...mapActions({
      getDevices: 'devices/getDevices',
    }),
    onAddDevice() {
      this.$socket.emit('term.device.add.batch', {
        items: [{ serial: this.serial, nickname: this.nickname }],
      });

      this.loading = true;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
