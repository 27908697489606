<template>
  <div
    class="d-flex">
    <div>
      <live-device-table
        v-model="device"
        :height="listHeight - 142">
      </live-device-table>
    </div>
    <div class="ml-2 mr-2 ma-auto">
      <div>
        <v-btn
          depressed
          @click="confirmShow = true"
          color="#ffffff"
          style="width: 64px; height: 120px;">
          <div
            class="pa-4 d-flex flex-column justify-center align-center">
            <v-avatar
              class="mb-4"
              size="32"
              color="#6F6F6F">
              <v-icon
                color="#ffffff">
                mdi-play
              </v-icon>
            </v-avatar>
            <div class="btn-play-all-label">
              <div>
                실시간
              </div>
              <div>
                영상보기
              </div>
            </div>
          </div>
        </v-btn>
      </div>
    </div>
    <div
      v-resize="onResizeContainer"
      ref="videosContainer"
      class="videos-container"
      :style="{ height: `${listHeight - 24}px` }">
      <v-row>
        <v-col
          class="ma-1 pa-0 flex-grow-0"
          v-for="(item, idx) in liveItems"
          :key="`item_${idx}`">
          <card-live
            :width="liveCard.width"
            :height="liveCard.height"
            v-bind="item"
            :trigger="trigger">
          </card-live>
        </v-col>
      </v-row>
    </div>
    <dlg-data-confirm
      @response="onConfirm"
      v-if="confirmShow"
      :show.sync="confirmShow">
    </dlg-data-confirm>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';

import LiveDeviceTable from '@/components/output/LiveDeviceTable.vue';
import CardLive from '@/components/card/live/CardLive.vue';
import DlgDataConfirm from '@/components/dialog/DlgDataConfirm.vue';

export default {
  name: 'Live',
  components: {
    LiveDeviceTable,
    CardLive,
    DlgDataConfirm,
  },
  data: () => ({
    device: {},
    trigger: new Vue(),
    liveCard: {
      width: 300,
      height: 210,
    },
    confirmShow: false,
  }),
  computed: {
    ...mapGetters({
      displaySize: 'display/size',
      liveSelected: 'devices/liveSelected',
      liveItems: 'devices/liveItems',
    }),
    listHeight() {
      return this.displaySize.height
        - (this.$vuetify.application.top + this.$vuetify.application.footer);
    },
  },
  watch: {
    liveSelected: {
      immediate: true,
      handler() {
        this.trigger.$emit('live.stop');
        this.trigger.$emit('reset');

        this.$nextTick(() => {
          this.onResizeContainer();
        });
      },
    },
  },
  methods: {
    onResizeContainer() {
      const { clientWidth, clientHeight } = this.$refs.videosContainer;
      let n = 1;

      while (n < 3) {
        const width = clientWidth / n;
        const height = ((width - 16) * 0.5625) + 51;
        const calcHeight = height * Math.ceil(this.liveItems.length / n);

        if (calcHeight > clientHeight) {
          n += 1;
        } else {
          break;
        }
      }

      this.liveCard.width = (clientWidth / n) - 8;
      this.liveCard.width = (this.liveCard.width > 300) ? this.liveCard.width : 300;
      this.liveCard.height = ((this.liveCard.width - 16) * 0.5625) + 51;
    },
    onConfirm(result) {
      if (result) {
        this.trigger.$emit('live.play');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.videos-container {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .row {
    margin: -4px;
  }
}

.btn-play-all-label {
  font-weight: bold;
  font-size: 1rem;
  color: #4E4E4E;
}
</style>
