var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"mobile-breakpoint":null,"show-select":"","item-key":"serial","headers":_vm.headers,"items":_vm.filteredItems,"footer-props":{
      itemsPerPageOptions: [50, 100, -1],
    },"hide-default-header":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
    var props = ref.props;
    var on = ref.on;
return [_c('device-table2-header',_vm._g(_vm._b({},'device-table2-header',props,false),on))]}},{key:"item.temp_flag",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDeviceAllowState(item))+" ")]}}]),model:{value:(_vm.selectedItems2),callback:function ($$v) {_vm.selectedItems2=$$v},expression:"selectedItems2"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }