<template>
  <div
    ref="container">
    <v-hover>
      <template v-slot:default="{ hover }">
        <div
          :style="styleVideoContainer"
          class="ma-auto video-container">
          <v-overlay
            color="#c7c7c7"
            opacity="0"
            z-index="1"
            :value="(!playing) || loading || hover"
            absolute>
            <div
              :style="styleVideoContainer"
              class="control-container">
              <v-btn
                class="btn-control btn-control-play"
                @click="$emit((!playing) ? 'play' : 'stop')"
                :loading="loading"
                color="#6F6F6F"
                fab>
                <v-icon>
                  {{ (!playing) ? 'mdi-play' : 'mdi-stop' }}
                </v-icon>
              </v-btn>
              <v-btn
                @click="(fullscreen ? exitFullscreen : requestFullscreen)()"
                class="btn-control btn-control-fullscreen"
                color="#6F6F6F"
                icon>
                <v-icon>
                  {{ fullscreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen' }}
                </v-icon>
              </v-btn>
            </div>
          </v-overlay>
          <video
            class="video-controller"
            ref="video">
          </video>
        </div>
      </template>
    </v-hover>
  </div>
</template>

<script>
import Hls from 'hls.js';

export default {
  name: 'LivePlayer',
  props: {
    src: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 320,
    },
    height: {
      type: Number,
      default: 180,
    },
  },
  data: () => ({
    player: null,
    playing: false,
    minPlayTime: 15,
    updatedRetry: 5,
    totalDuration: 0,
    currentTime: 0,
    ended: false,
    fullscreen: false,
    requestedFullscreen: false,
    loadingTimeout: null,
  }),
  computed: {
    styleVideoContainer() {
      return this.fullscreen ? {
        width: '100vw',
        height: '100vh',
      } : {
        width: `${this.width}px`,
        height: `${this.height}px`,
      };
    },
  },
  watch: {
    src: {
      immediate: true,
      handler(src) {
        if (src !== '') {
          this.initPlayer(src);
        } else {
          this.releasePlayer();
        }
      },
    },
  },
  mounted() {
    const { video } = this.$refs;

    video.addEventListener('loadedmetadata', () => {
      this.$emit('update:loading', false);
      this.loadingTimeout = null;
      video.play();
    });

    video.addEventListener('timeupdate', () => {
      this.$emit('timeupdate', {
        currentTime: video.currentTime,
        totalDuration: this.totalDuration,
      });

      this.currentTime = video.currentTime;

      if (this.ended && ((video.currentTime + 0.5) >= this.totalDuration)) {
        video.pause();
        this.releasePlayer();
        this.$emit('stopped');
      }
    });

    this.$refs.container.onfullscreenchange = () => {
      if (this.requestedFullscreen) {
        this.fullscreen = true;
        this.requestedFullscreen = false;
      } else {
        this.fullscreen = false;
      }
    };
  },
  methods: {
    initPlayer(src) {
      this.$emit('update:loading', true);

      if (this.player != null) {
        this.player.detachMedia();
        this.player.destroy();
        this.player = null;
      }

      // loading 타임 아웃 설정
      this.loadingTimeout = setTimeout(() => {
        this.$emit('update:loading', false);
        this.loadingTimeout = null;
      }, 30 * 1000);

      this.player = new Hls({
        debug: false,
        manifestLoadingMaxRetry: 10,
        manifestLoadingMaxRetryTimeout: 1000,
        levelLoadingMaxRetry: 3,
        levelLoadingMaxRetryTimeout: 1000,
        flagLoadingMaxRetry: 3,
        flagLoadingMaxRetryTimeout: 1000,
      });

      this.player.loadSource(src);
      this.player.attachMedia(this.$refs.video);

      this.playing = true;

      // 이벤트 등록
      this.player.on(Hls.Events.ERROR, (event, data) => {
        console.log('Error:', event, data);

        if (data.details !== 'bufferStalledError') {
          this.releasePlayer();
          this.$emit('stopped');
        }
      });

      this.player.on(Hls.Events.LEVEL_UPDATED, (event, data) => {
        if (data.details.updated) {
          this.totalDuration = data.details.driftEnd;
        }

        if (((this.minPlayTime - 2) < this.currentTime)
          && (this.updatedRetry < data.details.misses)) {
          this.ended = true;
        }

        if (this.ended && ((this.currentTime + 0.5) >= this.totalDuration)) {
          const { video } = this.$refs;

          video.pause();

          this.releasePlayer();
          this.$emit('stopped');
        }
      });
    },
    releasePlayer() {
      if (this.playing) {
        this.playing = false;

        this.player.stopLoad();
        this.totalDuration = 0;
        this.currentTime = 0;
        this.ended = false;

        this.$emit('update:src', '');
      }
    },
    requestFullscreen() {
      this.requestedFullscreen = true;
      this.$refs.container.requestFullscreen();
    },
    exitFullscreen() {
      document.exitFullscreen();
    },
  },
};
</script>

<style lang="scss" scoped>
.video-container {
  border-radius: .5rem;
  background-color: #C8C8C8;
  position: relative;

  &-fullscreen {
    width: 100%;
    height: 100%;
  }
}

.video-controller {
  background-color: #C8C8C8;
  border-radius: .5rem;
  width: 100%;
  height: 100%;
}

.control-container {
  width: 100%;
  height: 100%;

  .btn-control {
    position: absolute;

    &-play {
      left: calc(50% - 28px);
      top: calc(50% - 28px);
    }

    &-fullscreen {
      right: .5rem;
      bottom: .5rem;
    }
  }
}
</style>
