<template>
  <layout-card
    :width="300"
    title="블랙박스 상세 정보">
    <template v-slot:body>
      <div>
        <table class="pl-2 pr-2 card-info-list">
          <tr
            v-for="(item, idx) in info.items"
            :key="`item_${idx}`">
            <td class="card-info-title">
              {{ item.title }}
            </td>
            <td class="card-info-value">
              {{ self[item.key] }}
            </td>
          </tr>
          <tr>
            <td class="card-info-title">
              운행이력
            </td>
            <td class="card-info-value">
              <v-btn
                color="#A3A3A3"
                block
                small
                outlined>
                이력보기
              </v-btn>
            </td>
          </tr>
          <tr>
            <td class="card-info-title">
              운행경로
            </td>
            <td class="card-info-value">
              <v-btn
                @click="getLocationLog({ serial })"
                color="#A3A3A3"
                block
                small
                outlined>
                경로보기
              </v-btn>
            </td>
          </tr>
          <tr>
            <td class="card-info-title">
              실시간 영상
            </td>
            <td class="card-info-value">
              <v-btn
                @click="checkOnline"
                color="#A3A3A3"
                block
                small
                outlined>
                영상보기
              </v-btn>
            </td>
          </tr>
        </table>
      </div>
      <dlg-live-player
        v-if="live.show"
        :show.sync="live.show"
        :serial="serial"
        :name="$attrs.name"
        :type="live.type">
      </dlg-live-player>
      <dlg-data-confirm
        @response="onConfirm"
        v-if="confirmShow"
        :show.sync="confirmShow">
      </dlg-data-confirm>
    </template>
  </layout-card>
</template>

<script>
import util from '@/util';
import { mapActions, mapGetters } from 'vuex';
import MixinCheckOnline from '@/components/card/MixinCheckOnline';
import LayoutCard from '@/components/card/LayoutCard.vue';
import DlgLivePlayer from '@/components/dialog/DlgLivePlayer.vue';
import DlgDataConfirm from '@/components/dialog/DlgDataConfirm.vue';

export default {
  name: 'CardDeviceDetail',
  mixins: [MixinCheckOnline],
  components: {
    LayoutCard,
    DlgLivePlayer,
    DlgDataConfirm,
  },
  props: {
    serial: {
      type: String,
      default: '-',
    },
    model: {
      type: String,
      default: '-',
    },
    gps: {
      type: Object,
      default: null,
    },
    storage: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    info: {
      items: [
        { title: '차량 위치', key: 'address' },
        { title: '메모리 용량', key: 'totalMemory' },
        { title: '오늘 운행시간', key: 'statTime' },
        { title: '오늘 운행거리', key: 'dummy' },
        { title: '당월 운행시간', key: 'dummy' },
        { title: '당월 운행거리', key: 'dummy' },
        { title: '오늘 이벤트', key: 'eventsD1' },
        { title: '당월 이벤트', key: 'eventsMonth' },
      ],
    },
    geocoder: new window.kakao.maps.services.Geocoder(),
    address: '',
    live: {
      show: false,
      type: 3,
    },
  }),
  computed: {
    ...mapGetters({
      totalStatTime: 'stat/time',
      totalStatEvent: 'stat/events',
    }),
    self() {
      return this;
    },
    totalMemory() {
      return `${this.storage} GB`;
    },
    statTime() {
      let ret = '0 시간';

      const idx = this.totalStatTime.each.findIndex((item) => (item.serial === this.serial));
      const time = (idx >= 0) ? this.totalStatTime.each[idx].item[0] : 0;

      if (time > 0) {
        const hour = Math.floor(time / 60);
        const min = time - (hour * 60);
        ret = `${hour} 시간 ${min} 분`;
      }

      return ret;
    },
    eventsD1() {
      const idx = this.totalStatEvent.each.findIndex((item) => (item.serial === this.serial));
      return `${(idx >= 0) ? this.totalStatEvent.each[idx].item[0] : 0} 개`;
    },
    eventsMonth() {
      const idx = this.totalStatEvent.each.findIndex((item) => (item.serial === this.serial));
      return `${(idx >= 0) ? this.totalStatEvent.each[idx].item[4] : 0} 개`;
    },
    dummy() {
      return '-';
    },
  },
  watch: {
    serial: {
      handler() {
        this.resetLocationLog();
      },
    },
    gps: {
      immediate: true,
      handler() {
        if (this.gps != null) {
          const position = util.location.gpsToPosition(this.gps);

          this.searchDetailAddrFromCoords(position, (result, status) => {
            console.log('result:', status, result[0]);
            if (status === window.kakao.maps.services.Status.OK) {
              this.address = (result[0].road_address != null)
                ? result[0].road_address.address_name
                : result[0].address.address_name;
            } else {
              this.address = '-';
            }
          });
        } else {
          this.address = '-';
        }
      },
    },
  },
  mounted() {
    console.log('this:', this.self);
  },
  methods: {
    ...mapActions({
      getLocationLog: 'location/getLocationLog',
      resetLocationLog: 'location/resetLocationLog',
    }),
    searchDetailAddrFromCoords(position, cb) {
      this.geocoder.coord2Address(position.getLng(), position.getLat(), cb);
    },
    startLive() {
      this.live.show = true;
    },
    onConfirm(result) {
      if (result) {
        this.startLive();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-info-list {
  border-collapse: separate;
  border-spacing: 0 8px;
  width: 100%;

  .card-info-title {
    vertical-align: top;
    color: #989898;
    font-size: 1rem;
    font-weight: bold;
    text-align: left;
    width: 96px;
  }

  .card-info-value {
    font-size: 1rem;
    font-weight: bold;
    text-align: left;
  }
}
</style>
