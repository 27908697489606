<template>
  <layout-accent-card
    title="블랙박스 관리"
    :value="deviceCount"
    prepend-string="등록"
    append-string="대"
    :icon="icon"
    :width="260"
    :min-width="200"
    :height="200">
    <template v-slot:extension>
      <div class="btn-container">
        <v-btn
          @click="$emit('show:more')"
          color="#A3A3A3"
          outlined
          block>
          블랙박스 등록
        </v-btn>
      </div>
    </template>
  </layout-accent-card>
</template>

<script>
import { mapGetters } from 'vuex';
import LayoutAccentCard from '@/components/card/LayoutAccentCard.vue';
import Icon from '@/assets/icon_06.svg';

export default {
  name: 'CardManageDevice',
  components: {
    LayoutAccentCard,
  },
  data: () => ({
    icon: Icon,
  }),
  computed: {
    ...mapGetters({
      devices: 'devices/devices',
    }),
    deviceCount() {
      return this.devices.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-container {
  margin-top: 24px;
}
</style>
