<template>
  <layout-card
    title="녹화 파일"
    :value="count"
    prepend-string="오늘"
    append-string="건"
    :icon="icon"
    :width="300"
    :height="200">
    <template v-slot:body>
      <div class="d-flex flex-row">
        <div class="pr-2 pt-4 pb-4">
          <v-avatar
            color="#e4eff1"
            size="88">
            <img
              class="pa-1"
              alt="icon"
              :src="icon"/>
          </v-avatar>
        </div>
        <div class="pa-2 d-flex flex-column flex-grow-1">
          <div class="d-flex pt-6 pb-8 card-info-value flex-grow-1">
            <div class="ma-auto">
              상시 / 주차 / 이벤트
            </div>
          </div>
          <div>
            <v-btn
              @click="checkOnline"
              color="#A3A3A3"
              outlined
              block>
              목록보기
            </v-btn>
          </div>
        </div>
      </div>
      <dlg-data-confirm
        @response="onConfirm"
        v-if="confirmShow"
        :show.sync="confirmShow">
      </dlg-data-confirm>
    </template>
  </layout-card>
</template>

<script>
import MixinCheckOnline from '@/components/card/MixinCheckOnline';
import Icon from '@/assets/icon_10.svg';
import { mapGetters } from 'vuex';
import LayoutCard from '@/components/card/LayoutCard.vue';
import DlgDataConfirm from '@/components/dialog/DlgDataConfirm.vue';

export default {
  name: 'CardEventRecord',
  mixins: [MixinCheckOnline],
  components: {
    LayoutCard,
    DlgDataConfirm,
  },
  props: {
    serial: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    icon: Icon,
  }),
  computed: {
    ...mapGetters({
      devices: 'devices/devices',
      statEvents: 'stat/events',
    }),
    count() {
      const events = this.statEvents.each.find((item) => (item.serial === this.serial));
      return (events != null) ? events.item[0] : 0;
    },
  },
  methods: {
    onConfirm(result) {
      if (result) {
        this.$emit('show:more');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-container {
  margin-top: 24px;
  padding: .5rem;
  width: 156px;
}

.card-info-value {
  color: #989898;
  font-size: 1rem;
  font-weight: bold;
  text-align: left;
  word-break: keep-all;
}
</style>
