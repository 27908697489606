<template>
  <layout-dialog
    title="녹화 파일 목록"
    :show.sync="showFlag"
    :max-width="800">
    <template v-slot:body>
      <div>
        <square-tabs
          v-model="modes.select">
          <square-tab
            v-for="(item, idx) in modes.items"
            :key="`tab_${idx}`">
            {{ item.text }}
          </square-tab>
        </square-tabs>
        <v-progress-linear
          :indeterminate="eventVideo.loading"
          color="#373737">
        </v-progress-linear>
        <v-virtual-scroll
          class="mt-2 event-list"
          :bench="5"
          :items="eventVideo.items"
          :height="listHeight"
          item-height="48">
          <template v-slot:default="{ index, item }">
            <template>
              <div
                class="pl-2 pr-2"
                :key="`item_${index}`">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.file }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <div
                      class="d-flex">
                      <div
                        class="ch-select">
                        <v-select
                          v-model="item.type"
                          dense
                          hide-details
                          color="#929292"
                          item-text="text"
                          item-value="value"
                          :items="item.frames.map((frame, idx) => ({
                            text: `${idx + 1} 채널`,
                            value: idx,
                            frame: frame
                          }))">
                        </v-select>
                      </div>
                      <v-btn
                        @click="startPlayback({
                          serial,
                          fileName: item.file,
                          type: getType(item.type),
                        })"
                        class="ml-4"
                        color="#929292"
                        outlined
                        small>
                        <v-icon
                          small>
                          mdi-play
                        </v-icon>
                      </v-btn>
                      <v-btn
                        @click="startDownload({
                          serial,
                          fileName: item.file,
                          mode: mode,
                          type: getType(item.type),
                        })"
                        class="ml-4"
                        color="#929292"
                        outlined
                        small>
                        <v-icon
                          small>
                          mdi-download
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-list-item-action>
                </v-list-item>
                <v-divider
                  class="pl-2 pr-2"
                  v-if="index < eventVideo.items.length - 1">
                </v-divider>
              </div>
            </template>
          </template>
        </v-virtual-scroll>
      </div>
      <dlg-record-player
        v-if="playback.show"
        :show.sync="playback.show"
        :serial="serial"
        :file-name="playback.fileName"
        :mode="mode"
        :type="playback.type">
      </dlg-record-player>
    </template>
  </layout-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import LayoutDialog from '@/components/dialog/LayoutDialog.vue';
import DlgRecordPlayer from '@/components/dialog/DlgRecordPlayer.vue';
import SquareTabs from '@/components/output/SquareTabs.vue';
import SquareTab from '@/components/output/SquareTab.vue';

export default {
  name: 'DlgEventVideo',
  components: {
    LayoutDialog,
    DlgRecordPlayer,
    SquareTabs,
    SquareTab,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    serial: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    modes: {
      items: [
        { value: 1, text: '상시녹화' },
        { value: 2, text: '주차녹화' },
        { value: 0, text: '이벤트녹화' },
      ],
      select: 0,
    },
    eventVideo: {
      items: [],
      loading: false,
    },
    playback: {
      show: false,
      fileName: '',
      type: 0,
    },
  }),
  computed: {
    ...mapGetters({
      displaySize: 'display/size',
    }),
    listHeight() {
      return this.displaySize.height - 265;
    },
    showFlag: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      },
    },
    mode() {
      return this.modes.items[this.modes.select].value;
    },
  },
  watch: {
    mode: {
      immediate: true,
      handler() {
        if (this.serial != null) {
          this.getEventVideo();
        }
      },
    },
  },
  sockets: {
    'playback.list.get': function _(data) {
      const serial = (data.serial != null) ? data.serial : data.target.slice(3);

      console.log('data:', serial, data);

      if (serial != null && serial === this.serial) {
        if (data.result === 'success') {
          if (this.mode === data.mode) {
            this.eventVideo.items = data.items.map((item) => ({
              ...item,
              type: 0,
            }));

            this.eventVideo.loading = false;
          }
        } else {
          let { message } = data;

          if (data.name === 'ETIMEOUT') {
            message = '블랙박스 응답이 없습니다';
          }

          this.$store.dispatch('alert/setAlert', {
            message: `[${this.serial}] ${message}`,
            color: 'error',
          });

          this.eventVideo.loading = false;
        }
      }
    },
  },
  methods: {
    ...mapActions({
      startDownload: 'download/startDownload',
    }),
    getEventVideo() {
      this.eventVideo.loading = true;

      this.eventVideo.items = [];

      this.$socket.emit('playback.list.get', {
        serial: this.serial,
        mode: this.mode,
        protocol: 2,
      });
    },
    getType(value) {
      let type = 0;

      switch (value) {
        case 0: type = 3; break;
        case 1: type = 5; break;
        case 2: type = 6; break;
        case 3: type = 4; break;
        default: break;
      }

      return type;
    },
    startPlayback({ fileName, type }) {
      this.playback.fileName = fileName;
      this.playback.type = type;
      this.playback.show = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.ch-select {
  width: 120px;
  font-size: 1rem;
}

.event-list {
  background-color: #E5E5E5;
  overflow-y: scroll;
}

.v-list-item {
  min-height: 32px;

  .v-list-item__title {
    padding: {
      left: 2rem;
    }
    text-align: start;
    color: #4C4C4C;
  }

  .v-list-item__action {
    margin: {
      top: .5rem;
      bottom: .5rem;
    }
  }
}
</style>
