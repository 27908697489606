<template>
  <div
    @click="$emit('click')"
    class="square-tab"
    :class="{ 'square-tab-active': isActive }">
    <slot>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'SquareTab',
  data: () => ({
    isActive: false,
  }),
  mounted() {
    this.$on('isActive', (value) => { this.isActive = value; });
  },
};
</script>

<style lang="scss" scoped>
</style>
