<template>
  <v-dialog
    :max-width="maxWidth"
    :fullscreen="fullscreen"
    scrollable
    persistent
    :value="showFlag">
    <v-card>
      <v-card-title>
        <slot name="title">
          <span>{{ title }}</span>
          <v-spacer>
          </v-spacer>
          <v-btn
            @click="showFlag = false"
            icon>
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </slot>
      </v-card-title>
      <v-card-text>
        <slot name="body">
        </slot>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'LayoutDialog',
  props: {
    title: {
      type: String,
      default: 'Title',
    },
    show: {
      type: Boolean,
      default: true,
    },
    maxWidth: {
      type: Number,
      default: 600,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showFlag: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card__title {
  font-weight: bold !important;
}
</style>
