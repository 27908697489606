<template>
  <layout-card
    :width="300"
    :height="200"
    title="블랙박스 기본 정보">
    <template v-slot:body>
      <div>
        <table class="pr-2 pl-2 card-info-list">
          <tr
            v-for="(item, idx) in info.items"
            :key="`item_${idx}`">
            <td class="card-info-title">
              {{ item.title }}
            </td>
            <td class="card-info-value">
              {{ $props[item.key] }}
            </td>
          </tr>
        </table>
      </div>
    </template>
  </layout-card>
</template>

<script>
import LayoutCard from '@/components/card/LayoutCard.vue';

export default {
  name: 'CardDeviceInfo',
  components: {
    LayoutCard,
  },
  props: {
    name: {
      type: String,
      default: '-',
    },
    serial: {
      type: String,
      default: '-',
    },
    model: {
      type: String,
      default: '-',
    },
    ssid: {
      type: String,
      default: '-',
    },
  },
  data: () => ({
    info: {
      items: [
        { title: '블랙박스 이름', key: 'name' },
        { title: '시리얼 번호', key: 'serial' },
        { title: '블랙박스 모델', key: 'model' },
        { title: '라우터 SSID', key: 'ssid' },
      ],
    },
  }),
};
</script>

<style lang="scss" scoped>
.card-info-list {
  border-collapse: separate;
  border-spacing: 0 8px;
  width: 100%;

  .card-info-title {
    color: #989898;
    font-size: 1rem;
    font-weight: bold;
    text-align: left;
    width: 96px;
  }

  .card-info-value {
    font-size: 1rem;
    font-weight: bold;
    text-align: left;
  }
}
</style>
