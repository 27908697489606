function convert(value) {
  const tmp = Math.floor(value / 100);
  return tmp + ((value - (tmp * 100)) / 60);
}

export default {
  gpsToCoordinates(gps) {
    return {
      lat: convert(gps.latitude),
      lng: convert(gps.longitude),
    };
  },
  gpsToPosition(gps) {
    return new window.kakao.maps.LatLng(
      convert(gps.latitude),
      convert(gps.longitude),
    );
  },
};
