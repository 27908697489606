<template>
  <div>
    <div
      ref="container"
      :style="{ height: `${height}px` }">
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import util from '@/util';

import imgSelectedMarker from '@/assets/selected_marker.png';
import imgStartMarker from '@/assets/start_marker.png';
import imgEndMarker from '@/assets/end_marker.png';

export default {
  name: 'LocationMap',
  props: {
    height: {
      type: Number,
      default: 600,
    },
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    map: null,
    markers: {},
    icon: {
      selected: imgSelectedMarker,
      start: imgStartMarker,
      end: imgEndMarker,
    },
    path: {
      startMarker: null,
      endMarker: null,
      line: null,
    },
  }),
  computed: {
    ...mapGetters({
      devices: 'devices/devices',
      selectedDevice: 'devices/select',
      locationTasks: 'location/tasks',
      locationFlag: 'location/flag',
      coordinates: 'location/coordinates',
      level: 'location/level',
      locationLog: 'location/locationLog',
    }),
  },
  watch: {
    devices: {
      immediate: false,
      handler() {
        console.log('updated devices:', this.devices);
        this.addMarkers();
      },
    },
    selectedDevice: {
      handler() {
        this.addMarkers();
      },
    },
    locationFlag: {
      handler(flag) {
        console.log('locationFlag:', flag);
        if (flag) {
          this.$store.dispatch('location/flag', false);

          if (this.locationTasks.length > 0) {
            const { type, value } = this.locationTasks[0];
            this.$store.commit('location/popTask');

            switch (type) {
              case 'move': {
                const pos = this.map.getCenter();

                if (pos.lat !== value.lat || pos.lng !== value.lng) {
                  this.map.panTo(new window.kakao.maps.LatLng(value.lat, value.lng));
                } else {
                  this.$nextTick(() => {
                    this.$store.commit('location/flag', true);
                  });
                }
                break;
              }
              case 'level': {
                if (value !== this.map.getLevel()) {
                  this.map.setLevel(value);
                } else {
                  this.$nextTick(() => {
                    this.$store.commit('location/flag', true);
                  });
                }
                break;
              }
              default:
                break;
            }
          }
        }
      },
    },
    locationLog: {
      immediate: true,
      handler() {
        if (this.path.line != null) {
          this.path.line.setMap(null);
          this.path.line = null;
        }

        if (this.path.startMarker != null) {
          this.path.startMarker.setMap(null);
          this.path.startMarker = null;
        }

        if (this.path.endMarker != null) {
          this.path.endMarker.setMap(null);
          this.path.endMarker = null;
        }

        if (this.locationLog.items.length > 0) {
          const path = this.locationLog.items.map((item) => (
            new window.kakao.maps.LatLng(item.coordinates.lat, item.coordinates.lng)
          ));

          console.log('locationLog:', path);

          this.path.line = new window.kakao.maps.Polyline({
            path,
            strokeWeight: 5,
            strokeColor: '#c71717',
            strokeOpacity: 0.7,
            strokeStyle: 'solid',
            endArrow: true,
          });

          this.path.startMarker = new window.kakao.maps.Marker({
            position: new window.kakao.maps.LatLng(
              this.locationLog.items[0].coordinates.lat,
              this.locationLog.items[0].coordinates.lng,
            ),
            image: new window.kakao.maps.MarkerImage(
              this.icon.start,
              new window.kakao.maps.Size(42, 42),
              { offset: new window.kakao.maps.Point(21, 42) },
            ),
            zIndex: 2,
          });

          const lastIdx = this.locationLog.items.length - 1;
          this.path.endMarker = new window.kakao.maps.Marker({
            position: new window.kakao.maps.LatLng(
              this.locationLog.items[lastIdx].coordinates.lat,
              this.locationLog.items[lastIdx].coordinates.lng,
            ),
            image: new window.kakao.maps.MarkerImage(
              this.icon.end,
              new window.kakao.maps.Size(42, 42),
              { offset: new window.kakao.maps.Point(21, 42) },
            ),
            zIndex: 3,
          });

          this.path.startMarker.setMap(this.map);
          this.path.endMarker.setMap(this.map);
          this.path.line.setMap(this.map);

          console.log('length:', this.path.line.getLength());
        }
      },
    },
  },
  mounted() {
    console.log('selected:', this.icon.selected, this);
    this.initMap();
    this.addMarkers();
  },
  beforeDestroy() {
    this.releaseMap();
  },
  methods: {
    initMap() {
      if (this.map == null) {
        console.log(this.$refs.container);
        this.map = new window.kakao.maps.Map(this.$refs.container, {
          center: new window.kakao.maps.LatLng(this.coordinates.lat, this.coordinates.lng),
          level: this.level,
        });

        this.map.addControl(
          new window.kakao.maps.ZoomControl(),
          window.kakao.maps.ControlPosition.RIGHT,
        );

        window.kakao.maps.event.addListener(
          this.map,
          'zoom_changed',
          this.zoomChanged,
        );

        window.kakao.maps.event.addListener(
          this.map,
          'dragend',
          this.dragend,
        );
      }
    },
    releaseMap() {
      window.kakao.maps.event.removeListener(
        this.map,
        'zoom_changed',
        this.zoomChanged,
      );

      window.kakao.maps.event.removeListener(
        this.map,
        'dragend',
        this.dragend,
      );
    },
    zoomChanged() {
      console.log('@# zoomchanged:');

      this.$nextTick(() => {
        this.$store.commit('location/level', this.map.getLevel());
        this.$store.dispatch('location/flag', true);
      });
    },
    dragend() {
      console.log('@# dragend:');

      this.$nextTick(() => {
        const pos = this.map.getCenter();
        this.$store.commit('location/coordinates', {
          lat: pos.getLat(),
          lng: pos.getLng(),
        });
        this.$store.dispatch('location/flag', true);
      });
    },
    addMarkers() {
      if (this.devices.length > 0) {
        let selected = null;

        this.devices.forEach((item) => {
          if (item.gps != null && item.gps.latitude !== 0) {
            if (item.serial !== this.devices[this.selectedDevice].serial) {
              this.addMarker(item);
            } else {
              selected = item;
            }
          }
        });

        if (selected != null && selected.gps != null && selected.gps.latitude !== 0) {
          this.addMarker(selected);
        }
      }
    },
    addMarker(item) {
      if (this.markers[item.serial] != null) {
        this.markers[item.serial].overlay.setMap(null);
        this.markers[item.serial].marker.setMap(null);

        this.markers[item.serial] = null;
      }

      const position = util.location.gpsToPosition(item.gps);

      const content = `<div class="map-overlay-container"><span>${item.name}</span></div>`;
      const overlay = new window.kakao.maps.CustomOverlay({
        map: this.map,
        position,
        content,
        yAnchor: 1,
      });

      let image = null;

      if (item.serial === this.devices[this.selectedDevice].serial) {
        image = new window.kakao.maps.MarkerImage(
          this.icon.selected,
          new window.kakao.maps.Size(42, 42),
          { offset: new window.kakao.maps.Point(21, 42) },
        );
      }

      this.markers[item.serial] = {
        marker: new window.kakao.maps.Marker({
          position,
          image,
          zIndex: 1,
        }),
        overlay,
      };

      this.markers[item.serial].marker.setMap(this.map);
    },
  },
};
</script>

<style lang="scss">
.map-overlay-container {
  background-color: white;
  padding: 8px;
  position:relative;
  bottom:55px;
  border-radius:6px;
  border: 1px solid #ccc;
  border-bottom:2px solid #ddd;
  float:left;

  &:nth-of-type(n) {
    border:0;
    box-shadow:0 1px 2px #888;
  }

  &:after {
    content:'';
    position:absolute;
    margin-left:-12px;
    left:50%;
    bottom:-12px;
    width:22px;
    height:12px;
    background:url('https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/vertex_white.png')
  }
}
</style>
