var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout-dialog',{attrs:{"title":"이벤트 목록","show":_vm.showFlag,"max-width":800},on:{"update:show":function($event){_vm.showFlag=$event}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',[_c('div',[_c('input-date-range',{on:{"click:search":_vm.getEventList},model:{value:(_vm.eventList.dateRange),callback:function ($$v) {_vm.$set(_vm.eventList, "dateRange", $$v)},expression:"eventList.dateRange"}})],1),_c('v-progress-linear',{attrs:{"indeterminate":_vm.eventList.loading,"color":"#373737"}}),_c('v-virtual-scroll',{staticClass:"event-list",attrs:{"bench":5,"items":_vm.eventList.items,"height":_vm.listHeight,"item-height":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [[_c('div',{key:("item_" + index)},[_c('v-list-item',[_c('v-list-item-icon',[_c('v-tooltip',{attrs:{"disabled":!_vm.hasSnapshot(item),"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"snapshot-container",attrs:{"height":"54","width":"96","src":("data:image/jpg;base64,\n                        " + (item.snapshot != null ? item.snapshot[item.channel + 1] : ''))}},'v-img',attrs,false),on))]}}],null,true)},[_c('div',{staticClass:"d-flex"},[_c('v-img',{staticClass:"snapshot-container",attrs:{"height":"194","width":"345","src":("data:image/jpg;base64,\n                        " + (item.snapshot != null ? item.snapshot[item.channel + 1] : ''))}})],1)])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.address)+" ")]),_c('v-list-item-subtitle',[_c('span',{staticClass:"event-msg"},[_vm._v(" "+_vm._s(_vm.getEventString(item))+" ")]),_vm._v(" | "+_vm._s(_vm.toLocalDate(item.timestamp))+" ")])],1),_c('v-list-item-action',[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"ch-select",staticStyle:{"width":"80px"}},[_c('v-select',{attrs:{"disabled":!_vm.hasSnapshot(item),"dense":"","hide-details":"","color":"#929292","item-text":"text","item-value":"value","items":_vm.getChannelItems(item)},model:{value:(item.channel),callback:function ($$v) {_vm.$set(item, "channel", $$v)},expression:"item.channel"}})],1),_c('v-btn',{staticClass:"ml-4",attrs:{"disabled":!_vm.isPlayable(item),"color":"#929292","outlined":"","small":""},on:{"click":function($event){_vm.startPlayback({
                        serial: _vm.serial,
                        fileName: item.file,
                        type: _vm.getChannel(item.channel),
                      })}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-play ")])],1),_c('v-btn',{staticClass:"ml-4",attrs:{"disabled":!_vm.isPlayable(item),"color":"#929292","outlined":"","small":""},on:{"click":function($event){_vm.startDownload({
                        serial: _vm.serial,
                        fileName: item.file,
                        mode: 0,
                        type: _vm.getChannel(item.channel),
                      })}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-download ")])],1)],1)])],1),(index < _vm.eventList.items.length - 1)?_c('v-divider'):_vm._e()],1)]]}}])})],1),(_vm.playback.show)?_c('dlg-record-player',{attrs:{"show":_vm.playback.show,"serial":_vm.serial,"file-name":_vm.playback.fileName,"mode":0,"type":_vm.playback.type},on:{"update:show":function($event){return _vm.$set(_vm.playback, "show", $event)}}}):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }