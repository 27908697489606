<template>
  <layout-card
    :width="300"
    :height="200"
    title="운행 데이터">
    <template v-slot:body>
      <table class="pl-2 pr-2 card-info-list">
        <tr
          v-for="(item, idx) in info.items"
          :key="`item_${idx}`">
          <td class="card-info-title">
            {{ item.title }}
          </td>
          <td class="card-info-value">
            {{ item.value }}
          </td>
        </tr>
      </table>
    </template>
  </layout-card>
</template>

<script>
import LayoutCard from '@/components/card/LayoutCard.vue';

export default {
  name: 'CardDriveInfo',
  components: {
    LayoutCard,
  },
  data: () => ({
    info: {
      items: [
        { title: '운행시간/일', value: '-' },
        { title: '운행거리/일', value: '-' },
        { title: '운행시간/월', value: '-' },
        { title: '운행시간/월', value: '-' },
        { title: '데이터 사용량', value: '-' },
      ],
    },
  }),
};
</script>

<style lang="scss" scoped>
.card-info-list {
  border-collapse: separate;
  border-spacing: 0 8px;

  .card-info-title {
    color: #989898;
    font-size: 1rem;
    font-weight: bold;
    text-align: left;
    width: 96px;
  }

  .card-info-value {
    font-size: 1rem;
    font-weight: bold;
    text-align: left;
  }
}
</style>
