<template>
  <thead>
    <tr>
      <th
        v-for="(item, idx) in headers"
        :key="`header_${idx}`"
        :style="{
          minWidth: `${item.minWidth}px`,
          maxWidth: `${item.maxWidth}px`,
          width: `${item.width}px`,
          verticalAlign: 'middle'
        }"
        class="text-center">
        <div
          v-if="item.value !== 'data-table-select'"
          class="d-flex align-center">
          <div
            v-if="item.filterable"
            class="pt-4 pb-2 header-col">
            <v-select
              v-if="item.filterable instanceof Array"
              :label="headers[idx].text"
              @change="onFilter(item.value, headers[idx].filterValue)"
              v-model="headers[idx].filterValue"
              :items="item.filterable"
              multiple
              hide-details
              dense>
              SELECT
            </v-select>
            <v-text-field
              v-else
              @input="onFilter(item.value, headers[idx].filterValue)"
              :label="headers[idx].text"
              v-model="headers[idx].filterValue"
              outlined
              hide-details
              dense>
            </v-text-field>
          </div>
          <div
            class="v-label pb-4 pt-4"
            v-else>
            <span>{{ headers[idx].text }}</span>
          </div>
          <div
            v-if="headers[idx].sortable">
            <v-btn
              @click="on.sort(item.value)"
              class="sort-btn"
              :class="{
                'sort-active': (options.sortBy[0] === item.value),
                'sort-desc': ((options.sortBy[0] === item.value)
                  && options.sortDesc[0]),
              }"
              small
              icon>
              <v-icon>
                mdi-chevron-up
              </v-icon>
            </v-btn>
          </div>
        </div>
        <div v-else>
          <v-checkbox
            :indeterminate="indeterminate"
            @change="onChange">
          </v-checkbox>
        </div>
      </th>
    </tr>
  </thead>
</template>

<script>
import Header from 'vuetify/lib/components/VDataTable/mixins/header';

export default {
  name: 'DeviceTable2Header',
  mixins: [Header],
  methods: {
    onChange(v) {
      console.log('!!!', v);
      this.$emit('toggle-select-all', v);
    },
  },
  computed: {
    indeterminate() {
      return this.someItems && !this.everyItem;
    },
  },
};
</script>

<style lang="scss" scoped>
.sort-active {
  opacity: 1 !important;
}

.sort-desc {
  transform: rotate(-180deg);
}

.sort-btn {
  opacity: .3;

  &:hover {
    opacity: .75;
  }
}

.header-col {
  width: 100%;
}
</style>
