<template>
  <v-dialog
    max-width="300"
    v-model="show"
    v-if="show"
    persistent>
    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-text
        class="text-left">
        <slot
          name="body"
          :message="message">
          <div v-html="message"></div>
        </slot>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="onClickResponse(false)"
          color="cancel"
          text>
          취소
        </v-btn>
        <v-btn
          @click="onClickResponse(true)"
          color="primary"
          text>
          확인
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DlgConfirm',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: 'Title',
    },
    message: {
      type: String,
      required: false,
      default: 'Message',
    },
  },
  methods: {
    onClickResponse(resp) {
      this.$emit('update:show', false);
      this.$emit('response', resp);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
