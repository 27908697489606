export default {
  props: {
    stat: {
      type: String,
      default: 'disconnected',
    },
  },
  data: () => ({
    confirmShow: false,
  }),
  methods: {
    checkOnline() {
      if (this.stat === 'connected') {
        this.confirmShow = true;
      } else {
        this.$store.dispatch('alert/setAlert', {
          message: '장치가 오프라인입니다.',
          color: 'error',
        });
      }
    },
  },
};
