<template>
  <div
    class="d-flex">
    <div>
      <device-table
        @dblclick="onZoomInLocation"
        v-model="device"
        :height="listHeight - 142">
      </device-table>
    </div>
    <div
      class="ml-4 flex-grow-1">
      <card-location-map
        :height="listHeight - 142">
      </card-location-map>
    </div>
    <div
      class="hidden-md-and-down">
      <div
        class="ml-4 d-flex flex-column">
        <div
          class="mb-2">
          <card-device-info
            v-bind="device">
          </card-device-info>
        </div>
        <div>
          <card-device-detail
            v-bind="device">
          </card-device-detail>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import util from '@/util';
import DeviceTable from '@/components/output/DeviceTable.vue';
import CardDeviceInfo from '@/components/card/devicelist/CardDeviceInfo.vue';
import CardDeviceDetail from '@/components/card/location/CardDeviceDetail.vue';
import CardLocationMap from '@/components/card/location/CardLocationMap.vue';

export default {
  name: 'Location',
  components: {
    DeviceTable,
    CardDeviceInfo,
    CardDeviceDetail,
    CardLocationMap,
  },
  data: () => ({
    device: {},
  }),
  computed: {
    ...mapGetters({
      displaySize: 'display/size',
    }),
    listHeight() {
      return this.displaySize.height
        - (this.$vuetify.application.top + this.$vuetify.application.footer);
    },
  },
  methods: {
    onZoomInLocation(item) {
      console.log('onZoomIn:', item);
      if (item.gps != null && item.gps.latitude !== 0) {
        this.$store.dispatch('location/focus', {
          coordinates: util.location.gpsToCoordinates(item.gps),
          level: 3,
        });
      }
    },
  },
};
</script>

<style scoped>

</style>
