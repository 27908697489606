<template>
  <layout-accent-card
    title="운행 이력"
    :value="'-'"
    prepend-string="오늘"
    append-string="건"
    :icon="icon"
    :width="300"
    :height="200">
    <template v-slot:extension>
      <div class="btn-container">
        <v-btn
          @click="$emit('show:more')"
          color="#A3A3A3"
          outlined
          block>
          이력보기
        </v-btn>
      </div>
    </template>
  </layout-accent-card>
</template>

<script>
import Icon from '@/assets/icon_11.svg';
import { mapGetters } from 'vuex';
import LayoutAccentCard from '@/components/card/LayoutAccentCard.vue';

export default {
  name: 'CardDriveLog',
  components: {
    LayoutAccentCard,
  },
  data: () => ({
    icon: Icon,
  }),
  computed: {
    ...mapGetters({
      devices: 'devices/devices',
    }),
    count() {
      return 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-container {
  margin-top: 24px;
  width: 100%;
}
</style>
