<template>
  <layout-accent-card
    title="이벤트 알림"
    :value="count"
    prepend-string="오늘"
    append-string="건"
    :icon="icon"
    :width="300"
    :height="200">
    <template v-slot:extension>
      <div class="btn-container">
        <v-btn
          @click="$emit('show:more')"
          color="#A3A3A3"
          outlined
          block>
          알림보기
        </v-btn>
      </div>
    </template>
  </layout-accent-card>
</template>

<script>
import Icon from '@/assets/icon_08.svg';
import { mapGetters } from 'vuex';
import LayoutAccentCard from '@/components/card/LayoutAccentCard.vue';

export default {
  name: 'CardAlarm',
  components: {
    LayoutAccentCard,
  },
  props: {
    serial: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    icon: Icon,
  }),
  computed: {
    ...mapGetters({
      devices: 'devices/devices',
      statAlarm: 'stat/events',
    }),
    count() {
      const alarm = this.statAlarm.each.find((item) => (item.serial === this.serial));
      return (alarm != null) ? alarm.item[0] : 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-container {
  margin-top: 24px;
}
</style>
