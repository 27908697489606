import { render, staticRenderFns } from "./SquareTabs.vue?vue&type=template&id=97f623b2&scoped=true&"
import script from "./SquareTabs.vue?vue&type=script&lang=js&"
export * from "./SquareTabs.vue?vue&type=script&lang=js&"
import style0 from "./SquareTabs.vue?vue&type=style&index=0&id=97f623b2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97f623b2",
  null
  
)

export default component.exports