<template>
  <div
    style="margin: auto;"
    class="d-flex">
    <div
      style="overflow-y: auto; overflow-x: hidden;"
      :style="{ height: `${listHeight - 24}px` }">
      <v-row
        class="dashboard-card-list"
        align="start"
        justify="center">
        <v-col>
          <card-live-layout>
          </card-live-layout>
        </v-col>
        <v-col>
          <card-manage-device
            @show:more="addDevice.show = true">
          </card-manage-device>
        </v-col>
      </v-row>
    </div>
    <dlg-add-device-table
      v-if="addDevice.show"
      :show.sync="addDevice.show">
    </dlg-add-device-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CardLiveLayout from '@/components/card/settings/CardLiveLayout.vue';
import CardManageDevice from '@/components/card/settings/CardManageDevice.vue';
import DlgAddDeviceTable from '@/components/dialog/DlgAddDeviceTable.vue';

export default {
  name: 'Settings',
  components: {
    CardLiveLayout,
    CardManageDevice,
    DlgAddDeviceTable,
  },
  data: () => ({
    addDevice: {
      show: false,
    },
  }),
  computed: {
    ...mapGetters({
      displaySize: 'display/size',
      statRegistered: 'stat/registered',
      statTime: 'stat/time',
    }),
    listHeight() {
      return this.displaySize.height
        - (this.$vuetify.application.top + this.$vuetify.application.footer);
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-card-list {
  margin: -4px;

  .col {
    margin: 4px;
    padding: 0;
    flex-grow: 0;
  }
}
</style>
