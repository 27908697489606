var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout-dialog',{attrs:{"title":"녹화 파일 목록","show":_vm.showFlag,"max-width":800},on:{"update:show":function($event){_vm.showFlag=$event}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',[_c('square-tabs',{model:{value:(_vm.modes.select),callback:function ($$v) {_vm.$set(_vm.modes, "select", $$v)},expression:"modes.select"}},_vm._l((_vm.modes.items),function(item,idx){return _c('square-tab',{key:("tab_" + idx)},[_vm._v(" "+_vm._s(item.text)+" ")])}),1),_c('v-progress-linear',{attrs:{"indeterminate":_vm.eventVideo.loading,"color":"#373737"}}),_c('v-virtual-scroll',{staticClass:"mt-2 event-list",attrs:{"bench":5,"items":_vm.eventVideo.items,"height":_vm.listHeight,"item-height":"48"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [[_c('div',{key:("item_" + index),staticClass:"pl-2 pr-2"},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.file)+" ")])],1),_c('v-list-item-action',[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"ch-select"},[_c('v-select',{attrs:{"dense":"","hide-details":"","color":"#929292","item-text":"text","item-value":"value","items":item.frames.map(function (frame, idx) { return ({
                          text: ((idx + 1) + " 채널"),
                          value: idx,
                          frame: frame
                        }); })},model:{value:(item.type),callback:function ($$v) {_vm.$set(item, "type", $$v)},expression:"item.type"}})],1),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"#929292","outlined":"","small":""},on:{"click":function($event){_vm.startPlayback({
                        serial: _vm.serial,
                        fileName: item.file,
                        type: _vm.getType(item.type),
                      })}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-play ")])],1),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"#929292","outlined":"","small":""},on:{"click":function($event){_vm.startDownload({
                        serial: _vm.serial,
                        fileName: item.file,
                        mode: _vm.mode,
                        type: _vm.getType(item.type),
                      })}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-download ")])],1)],1)])],1),(index < _vm.eventVideo.items.length - 1)?_c('v-divider',{staticClass:"pl-2 pr-2"}):_vm._e()],1)]]}}])})],1),(_vm.playback.show)?_c('dlg-record-player',{attrs:{"show":_vm.playback.show,"serial":_vm.serial,"file-name":_vm.playback.fileName,"mode":_vm.mode,"type":_vm.playback.type},on:{"update:show":function($event){return _vm.$set(_vm.playback, "show", $event)}}}):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }