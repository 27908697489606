<template>
  <div>
    <v-data-table
      v-model="selectedItems2"
      :mobile-breakpoint="null"
      show-select
      item-key="serial"
      :headers="headers"
      :items="filteredItems"
      :footer-props="{
        itemsPerPageOptions: [50, 100, -1],
      }"
      hide-default-header>
      <template v-slot:header="{ props, on }">
        <device-table2-header
          v-bind="props"
          v-on="on">
        </device-table2-header>
      </template>
      <template v-slot:item.temp_flag="{ item }">
        {{ getDeviceAllowState(item) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import * as time from '@kim5257/js-time';
import DeviceTable2Header from '@/components/output/DeviceTable2Header.vue';

export default {
  name: 'DeviceTable2',
  components: {
    DeviceTable2Header,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    selectedItems: {
      type: Array,
      default: () => ([]),
    },
  },
  data: () => ({
    headers: [
      {
        text: 'Serial',
        value: 'serial',
        filterable: true,
        filterValue: '',
        sortable: true,
      },
      {
        text: '이름',
        value: 'name',
        filterable: true,
        filterValue: '',
        sortable: true,
      },
      {
        text: '비고',
        value: 'temp_flag',
        filterable: true,
        filterValue: '',
        sortable: true,
      },
    ],
    filteredItems: [],
  }),
  computed: {
    selectedItems2: {
      get() {
        return this.selectedItems;
      },
      set(value) {
        this.$emit('update:selectedItems', value);
      },
    },
  },
  watch: {
    items: {
      immediate: true,
      handler() {
        this.onFilter();
      },
    },
  },
  methods: {
    toUptimeText(uptime) {
      let text = '-';

      if (uptime != null) {
        const days = Math.floor(uptime / (60 * 60 * 24));
        const seconds = uptime - (days * 60 * 60 * 24);
        const date = new Date(0);
        date.setSeconds(seconds);

        text = `${days}일 ${date.toISOString().substr(11, 8)}`;
      }

      return text;
    },
    toUsageString(usage) {
      let ret = usage / 1024;
      let unit = 'MB';

      if (ret > 1024) {
        ret /= 1024;
        unit = 'GB';
      }

      ret = Math.round(ret * 10) / 10;

      return `${ret} ${unit}`;
    },
    toLocalDate(timestamp) {
      return (timestamp != null) ? time.localTimeString(timestamp) : '-';
    },
    onFilter() {
      this.filteredItems = this.items.filter((item) => {
        let found = true;

        this.headers.forEach((header) => {
          if (header.filterable != null && header.filterable) {
            const value = item[header.value];

            if (value != null) {
              const { filterable, filterValue } = header;

              if (filterable instanceof Array) {
                found = found && filterValue.includes(value);
              } else if (filterable instanceof Function) {
                // found = found && filterable(item, hdrValue, filterValue, this);
              } else {
                const str = value.toString();
                found = found && ((str != null && str.includes(filterValue))
                  || filterValue === '');
              }
            } else {
              found = found && (value == null && header.filterValue === '');
            }
          }
        });

        return found;
      });
    },
    getDeviceAllowState(item) {
      let msg = '';
      switch (item.temp_flag) {
        case 1: msg = '승인 대기'; break;
        case 2: msg = '승인 거부'; break;
        default: break;
      }
      return msg;
    },
  },
};
</script>

<style lang="scss" scoped>
.sort-active {
  opacity: 1 !important;
}

.sort-desc {
  transform: rotate(-180deg);
}

.sort-btn {
  opacity: .3;

  &:hover {
    opacity: .75;
  }
}

.header-col {
  width: 100%;
}
</style>
