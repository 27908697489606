<template>
  <div
    class="square-tabs">
    <div
      class="square-tabs-bar"
      ref="bar">
      <slot>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SquareTabs',
  props: {
    value: {
      type: Number,
      required: false,
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.$nextTick(() => {
          this.$slots.default.forEach((item, idx) => {
            console.log(item, item.componentInstance, idx, value);
            item.componentInstance.$emit('isActive', (value === idx));
          });
        });
      },
    },
  },
  mounted() {
    console.log('slot:', this.$slots.default);
    this.$slots.default.forEach((item, idx) => {
      item.componentInstance.$on('click', () => {
        this.$emit('input', idx);
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.square-tabs {
  background-color: #7C7C7C;
  color: #ffffff;

  padding: {
    left: 1rem;
    right: 1rem;
    top: .25rem;
    bottom: .25rem;
  }

  .square-tabs-bar {
    display: flex;
    height: 32px;

    .square-tab {
      align-items: center;
      cursor: pointer;
      display: flex;
      flex: 0 1 auto;
      font-weight: bold;
      font-size: 1.2rem;
      justify-content: center;
      letter-spacing: 0.0892857143em;
      line-height: normal;
      min-width: 90px;
      max-width: 360px;
      outline: none;
      padding: 0 16px;
      position: relative;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      transition: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &::before {
        background-color: currentColor;
        bottom: 0;
        content: "";
        left: 0;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
      }

      &:hover::before {
        opacity: 0.14;
      }

      &-active {
        background-color: #373737;
      }
    }
  }
}
</style>
